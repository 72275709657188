import React from 'react'
import JavaLeftBanner from './JavaLeftBanner';
import JavaRightBanner from './JavaRightBanner';
const Banner = () => {
  return (
    <section
      id="java"
      className="w-full pt-10 pb-20 flex flex-col gap-10 xl:gap-0 lgl:flex-row items-center border-b-[1px] font-titleFont border-b-black"
    >
      <JavaLeftBanner />
     <JavaRightBanner />
    </section>
  );
}

export default Banner