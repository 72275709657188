import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";


const JavaLeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Professional Coder.", "Full Stack Developer.", "UI Designer."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    
      <div className="w-full mx-3 lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
      <h2 className='text-2xl  text-slate-300 uppercase text-center'>JAVA TRAINING</h2>
        {/* <h1 className=" text-lg font-normal">NEW LIFE ENGINEERING</h1> */}
        <h4 className="  font-bold">Why should learn Java?</h4>
        <ul>
          <li>Java is a popular programming language used in many industries, including banking, finance, healthcare, and telecommunications. This means that there are many opportunities for freshers to start a career in Java development.</li>
          
        </ul>
        <h4 className="  font-bold">Introduction to Computing</h4>
        <ul>
          <li>Computer, Hardware, CPU, Main Memory, Secondary Storage System, USB, Input Devices, Software, System Software, Application Software</li>
          
        </ul>
                
        <h4 className="  font-bold">How Program Runs</h4>
        <ul>
          <li>How CPU Performs Tasks, Assembly Language, High Level Language, Compilers and Interpreters</li>

        </ul>
        <h4 className="  font-bold">Java Basics</h4>
        <ul>
          <li>Introduction, Java Runtime Envioronment - JRE, Java Virtual Machine, Java Basic Syntax, Data Types, Variables and Operators, Conditional, Loops, Functions, Arrays, Sorting, Searching</li>

        </ul>
        <h4 className="  font-bold">Object Oriented Programming</h4>
        <ul>
          <li>OOPs Introduction, Abstractions, Access Modifiers, Encapsulations, Exceptions Handling, Getter and Setter, Inheritance, Interfaces, Static
          </li>

        </ul>
        
      </div>

    </div>
    
  );
}

export default JavaLeftBanner