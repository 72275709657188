export const navLinksdata = [
  {
    _id: 1001,
    title: "Home",
    link: "home",
  },

 

  {
    _id: 1002,
    title: "Aptitude",
    link: "hero",
  },
  {
    _id: 1003,
    title: "Java - RDBMS",
    link: "java",
  },
  
];