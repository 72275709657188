import React from "react";
import { phone } from "../../assets/index";
const FooterBottom = () => {
  return (
    <div className="w-full py-2">
      <div class="relative container flex flex-col items-start px-6 mx-auto md:flex-row md:space-x-7">
        <div class="relative flex flex-col mt-2 p-6  bg-green-300 rounded-lg md:mt-2 md:w-1/3">
          <h5 class="pt-1 text-yellow-600 text-xl font-bold text-center capitalize md:text-left">
            Phone
          </h5>
          <h5 class="text-center w-60 text-xl font-bold text-gray-800 md:text-left">
            97899 11533
          </h5>
        </div>

        <div class="relative bg-green-300 flex flex-col mt-2 p-6  rounded-lg md:mt-2 md:w-1/3">
          <h5 class="pt-1 text-yellow-600 text-xl font-bold text-center capitalize md:text-left">
            Email
          </h5>
          <h5 class="text-center text-xl w-60 font-bold text-gray-800 md:text-left">
            rwithpower@gmail.com
          </h5>
        </div>

        <div class="relative bg-green-300 flex flex-col mt-2 p-6   rounded-lg md:mt-2 md:w-1/3">
          <h5 class="pt-1 text-xl text-yellow-600 font-bold text-center capitalize md:text-left">
            Name
          </h5>
          <h5 class="text-center text-xl w-60 font-bold text-gray-800 md:text-left">
            CT Sevuga Perumal
          </h5>
        </div>
      </div>

      <p className="text-center font-bold text-white-800 text-base">© Infowisdom</p>
    </div>
  );
};

export default FooterBottom;
