import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";


const JavaRightBanner = () => {
    const [text] = useTypewriter({
      words: ["Professional Coder.", "Full Stack Developer.", "UI Designer."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
    return (
    
      <div className="w-full mx-3 lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
      <h2 className='text-2xl  text-slate-300 uppercase text-center'>RDBMS & SQL TRAINING</h2>
        {/* <h1 className=" text-lg font-normal">NEW LIFE ENGINEERING</h1> */}
        
        <h4 className="  font-bold">Why should you know about Data?</h4>
        <ul>
          <li>We are living in a data-driven world, with 90% of the world's data created in the last two years. As such, businesses are heavily investing in data, with over 97% of organizations doing so.</li>
          
        </ul>
                
        <h4 className="  font-bold">Why Should you learn SQL?</h4>
        <ul>
          <li>SQL is the gold standard language for working with data in the real-world. Many jobs require it.
          Learning SQL can increase your marketability as a developer, leading to potential jobs like SQL Developer, Software Engineer, Data Scientist (machine Learning and AI), and Database Administrator.</li>
          <li>SQL is the most common method of accessing the immense amount of essential data stored in databases.</li>
          <li>SQL as a job skill is relevant in every industry.</li>
        </ul>
        <h4 className="  font-bold">Why should you attend this training?</h4>
        <ul>
          <li>Through this training you will learn:</li>
          <li>Client Server Architecture,Operational Database, Datawarehouse, OLTP Vs OLAP, RDBMS Concept,Data Types in SQL,INSERT, UPDATE & DELETE, SELECT Statements,
Column Aliases in SQL, Using Expressions, Data Sorting, WHERE Clause, Arithmatic Operators in SQL, Working with Multiple Conditions, Aggregate Functions in SQL, Aggregate Functions and GROUP BY,HAVING Clause, JOIN, Relationship.
</li>

        </ul>
        
        
      </div>

    </div>
    
  );
}

export default JavaRightBanner