import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";


const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Professional Coder.", "Full Stack Developer.", "UI Designer."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    
      <div className="w-full mx-3 lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
      <h2 className='text-2xl  text-slate-300 uppercase text-center'>INFOWISDOM SOFTWARE TRAINING</h2>
        {/* <h1 className=" text-lg font-normal">NEW LIFE ENGINEERING</h1> */}
        
        <h4 className=" text-lg font-normal">VISION</h4>
        <ul>
          <li>To be the leading IT training institute in India, renowned for nurturing talented individuals and fostering innovation in the technology industry.</li>
          
        </ul>
                
        <h4 className=" text-lg font-normal">MISSION</h4>
        <ul>
          <li>Our mission is to empower freshers by providing them with industry-relevant skills and comprehensive placement training to help them secure their dream job in the IT sector. We aim to bridge the gap between academic learning and real-world application by offering top-notch training in **Aptitude, RDBMS, SQL**, and **Java**, while enhancing employability through personalized career guidance and mock interviews.</li>

        </ul>
        <h4 className=" text-lg font-normal">CORE VALUES</h4>
        <ul>
          <li>At Infowisdom Training Center, we are committed to shaping confident, skilled, and job-ready professionals who can thrive in the fast-paced IT industry.</li>
          <br></br>
          <li>Our courses in **Aptitude, RDBMS, SQL**, and **Java** are designed to meet the specific needs of the IT industry. We cover all essential topics that employers look for when hiring fresh talent, ensuring you are job-ready from day one.</li>
          <br></br>
          <li>Learn from experienced industry professionals who have a deep understanding of the IT landscape.</li>
        </ul>
      </div>

    </div>
    
  );
}

export default LeftBanner