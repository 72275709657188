import React from 'react'
import Title from '../layouts/Title'
// import { feature } from "../../assets/index";

const Hero = () => {
  
    return (
      <>
<section id="hero" class="py-2 w-60% bg-gray-100">
  
      <div class="container mx-auto px-3 ">
        <h2 class="text-4xl mb-6 font-bold text-center text-green-800">Aptitude Training</h2>
        <p class="max-w-xs mx-auto text-center font-bold text-yellow-800 md:max-w-md">
        Quantitative Aptitude or QA is an integral section of any competitive exam. It tests a candidate's mathematical skills and analytical ability which is put to use in every step of life and career, no matter which industry you choose. Quantitative Aptitude is all about Mathematics and calculations.
        </p>
      </div>
    </section>
    <section id="features" class="pb-32 bg-gray-600">
    <div class="bg-white-600">
      <div
        class="relative container flex flex-col items-start px-6 mx-auto md:flex-row md:space-x-7"
      >
        {/* <!-- Horizontal Line --> */}
        <div
          class="hidden absolute top-24 w-10/12 left-16 h-3 bg-cyan md:block"
        ></div>
        {/* <!-- Vertical Line --> */}
        <div class="absolute w-2 left-1/2 h-full -ml-1 bg-cyan md:hidden"></div>

        
        <div
          class="relative flex flex-col mt-4 p-6 space-y-6 bg-yellow-300 rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-5 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-green-600 text-xl font-bold text-center capitalize md:text-left"
          >
            Number Systems
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Properties of numbers, divisibility rules, remainders, number series, and number puzzles.
          </p>
        </div>
        
        <div
          class="relative bg-yellow-300 flex flex-col mt-10 p-6 space-y-6  rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-10 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-green-600 text-xl font-bold text-center capitalize md:text-left"
          >
            Percentages
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Calculating percentages, percentage change, percentage increase/decrease, and applications.
          </p>
        </div>

        
        <div
          class="relative bg-yellow-300 flex flex-col mt-10 p-6 space-y-6  rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-10 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-xl text-green-600 font-bold text-center capitalize md:text-left"
          >
            Profit and Loss
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Concepts of cost price, selling price, profit, loss, discount, markup, and profit/loss percentage.
          </p>
        </div>
      </div>
      <div
        class="relative container flex flex-col items-start px-6 mx-auto md:flex-row md:space-x-7"
      >
        {/* <!-- Horizontal Line --> */}
        <div
          class="hidden absolute top-24 w-10/12 left-16 h-3 bg-cyan md:block"
        ></div>
        {/* <!-- Vertical Line --> */}
        <div class="absolute w-2 left-1/2 h-full -ml-1 bg-cyan md:hidden"></div>

        
        <div
          class="relative flex flex-col mt-4 p-6 space-y-6 bg-yellow-300 rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-5 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-green-600 text-xl font-bold text-center capitalize md:text-left"
          >
            Simple and Compound Interest
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Calculation of interest, principal amount, time period, and rate of interest.
          </p>
        </div>
        
        <div
          class="relative bg-yellow-300 flex flex-col mt-10 p-6 space-y-6  rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-10 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-green-600 text-xl font-bold text-center capitalize md:text-left"
          >
            Ratio and Proportion
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Understanding ratios, proportions, variations, and their applications.
          </p>
        </div>

        
        <div
          class="relative bg-yellow-300 flex flex-col mt-10 p-6 space-y-6  rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-10 md:left-16">
            

          </div> */}
          <h5
            class="pt-1 text-xl text-green-600 font-bold text-center capitalize md:text-left"
          >
             progressions
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Sequences, Series, Arithmetic progressions, The sum of an arithmetic series, Geometric progressions, The sum of a geometric series.
          </p>
        </div>
      </div>
      <div
        class="relative container flex flex-col items-start px-6 mx-auto md:flex-row md:space-x-7"
      >
        {/* <!-- Horizontal Line --> */}
        <div
          class="hidden absolute top-24 w-10/12 left-16 h-3 bg-cyan md:block"
        ></div>
        {/* <!-- Vertical Line --> */}
        <div class="absolute w-2 left-1/2 h-full -ml-1 bg-cyan md:hidden"></div>

        
        <div
          class="relative flex flex-col mt-4 p-6 space-y-6 bg-yellow-300 rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-5 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-green-600 text-xl font-bold text-center capitalize md:text-left"
          >
            Average
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Calculation of averages, weighted averages, and mean, median, and mode.
          </p>
        </div>
        
        <div
          class="relative bg-yellow-300 flex flex-col mt-10 p-6 space-y-6  rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-10 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-green-600 text-xl font-bold text-center capitalize md:text-left"
          >
            Time and Work
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Problems involving work done by individuals or groups, efficiency, and work rates.
          </p>
        </div>

        
        <div
          class="relative bg-yellow-300 flex flex-col mt-10 p-6 space-y-6  rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-10 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-xl text-green-600 font-bold text-center capitalize md:text-left"
          >
            Time and Distance
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Concepts of speed, distance, time, and their relationships.
          </p>
        </div>
      </div>
      <div
        class="relative container flex flex-col items-start px-6 mx-auto md:flex-row md:space-x-7"
      >
        {/* <!-- Horizontal Line --> */}
        <div
          class="hidden absolute top-24 w-10/12 left-16 h-3 bg-cyan md:block"
        ></div>
        {/* <!-- Vertical Line --> */}
        <div class="absolute w-2 left-1/2 h-full -ml-1 bg-cyan md:hidden"></div>

        
        <div
          class="relative flex flex-col mt-4 p-6 space-y-6 bg-yellow-300 rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-5 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-green-600 text-xl font-bold text-center capitalize md:text-left"
          >
            Pipes and Cisterns
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Problems involving filling and emptying tanks by pipes or cisterns.
          </p>
        </div>
        
        <div
          class="relative bg-yellow-300 flex flex-col mt-10 p-6 space-y-6  rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-10 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-green-600 text-xl font-bold text-center capitalize md:text-left"
          >
            Permutations and Combinations
          </h5>
          <p class="text-center text-gray-800 md:text-left">
          Arrangements, selections, and the order of events.
          </p>
        </div>

        
        <div
          class="relative bg-yellow-300 flex flex-col mt-10 p-6 space-y-6  rounded-lg md:mt-8 md:w-1/3"
        >
          
          {/* <div class="absolute -ml-10 left-1/2 -top-10 md:left-16">
            

          </div> */}
          <h5
            class="pt-6 text-xl text-green-600 font-bold text-center capitalize md:text-left"
          >
            Mixture and Alligation
          </h5>
          <p class="text-center text-gray-800 md:text-left">
           Rule of Allegation, Diagram Method, Mean Price, Alligation Medial, Alligation Alternate.
          </p>
        </div>
      </div>
      </div>
    </section>
    </>
    );
  }
  
  export default Hero